import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import {css} from '@emotion/core'
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer'
import {fonts} from '../lib/typography'
import {bpMaxSM} from '../lib/breakpoints'

export default ({data}) => {
  const {site, mdx} = data;
  const {frontmatter} = mdx;
  return (
  <Layout site={site} frontmatter={frontmatter}>
    <SEO frontmatter={frontmatter} />
    <Container noVerticalPadding={frontmatter.noVerticalPadding === undefined ? true : frontmatter.noVerticalPadding}>
      <Workshop data={data} />
    </Container>
  </Layout>
)}

function Workshop({data: {site, mdx}}) {
  const {frontmatter, body} = mdx;
  const {title} = frontmatter;
  
  return (
    <article
      css={css`
        width: 100%;
        display: flex;
        twitter-widget {
          margin-left: auto;
          margin-right: auto;
        }
      `}
    >
      <Container
        css={css`
          padding-top: 0;
        `}
      >
        <div
          css={css`
            padding: 40px 0 0 0;
            ${bpMaxSM} {
              padding: 20px 0 0 0;
            }
            h1 {
              font-size: 1.75rem;
              font-family: ${fonts.semibold}, sans-serif;
            }
          `}
        >
          <h1>{title} Workshop</h1>
        </div>

        <div
          css={css`
            display: flex;
            justify-content: center;
            h3,
            span {
              text-align: center;
              font-size: 15px;
              opacity: 0.6;
              font-family: ${fonts.regular}, sans-serif;
              font-weight: normal;
              margin: 0 5px;
            }
          `}
        />
        <MDXRenderer>{body}</MDXRenderer>
        
      </Container>
    </article>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      ...site
    }
    mdx(fields: { id: { eq: $id } }) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        banner {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        slug
        keywords
      }
      body
    }
  }
`